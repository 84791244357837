<template>
  <a-form-model ref="formModelRef" :model="form" layout="inline">
    <a-row v-if="!isCollect || form.specifications.length">
      <a-col :span="3" class="label">商品规格：</a-col>
      <a-col :span="21">
        <div class="specifications-box">
          <div v-for="(item, index) in form.specifications" :key="`specifications${index}`" class="specification-item">
            <div class="line">
              <a v-if="!isCollect" href="javascript:;" class="btn-del" @click="handleSkuDelete(index)">
                <a-icon class="icon-close" type="close" />
              </a>
              <span class="label ant-form-item-required">规格名：</span>
              <a-form-model-item :class="{'has-error': item.error}">
                <a-input v-model.trim="item.name" placeholder="请输入" @input="handleSkuValidate(arguments[0], item, 'sku')" :disabled="isCollect" />
              </a-form-model-item>
              <span class="custom-error">{{item.error}}</span>
            </div>
            <div class="specification-detail">
              <span class="label ant-form-item-required">规格值：</span>
              <div class="detail-list">
                <a-form-model-item v-for="(specificationDetail, specificationIndex) in form.specifications[index].children" :key="`specificationDetail${index}${specificationIndex}`" :class="{'has-error': specificationDetail.error}" :help="specificationDetail.error">
                  <a-input v-model.trim="specificationDetail.name" placeholder="请输入" @input="handleSkuValidate(arguments[0], specificationDetail, 'detail', form.specifications[index].children)" :disabled="isCollect" />
                  <a v-if="!isCollect" href="javascript:;" class="btn-del" @click="handleSkuDetailDelete(index, specificationIndex)">
                    <a-icon class="icon-close" type="close" />
                  </a>
                </a-form-model-item>
                <template v-if="!isCollect">
                  <a-button v-if="form.specifications[index].children.length <= 50" style="margin-top: 4px" @click="handleAddSpecificationDetail(index)">
                    添加规格值
                  </a-button>
                  <a-popover v-else>
                    <span slot="content">最多支持50组规格值</span>
                    <a-button style="margin-top: 4px" disabled>添加规格值</a-button>
                  </a-popover>
                </template>
              </div>
            </div>
          </div>
          <div class="line" v-if="!isCollect">
            <a-button v-if="form.specifications.length < skuLimit" @click="handleAddSpecification">添加规格项目</a-button>
            <a-popover v-else>
              <span slot="content">最多支持3组规格</span>
              <a-button disabled>添加规格项目</a-button>
            </a-popover>
          </div>
        </div>
        <p class="extra" v-if="!isCollect">如有颜色、尺码等多种规格，请添加商品规格</p>
      </a-col>
    </a-row>
    <a-row class="sku-setting" v-if="form.skuList.length">
      <a-col :span="3" class="label">商品明细：</a-col>
      <a-col :span="21">
        <a-table bordered class="custom-table" :columns="columns" :data-source="form.skuList" rowKey="skuCode" :pagination="false">
          <!-- 自定义标题 -->
          <template v-for="item in needEdit.filter(x => x.tip)" :slot="`${item.variable}Title`">
            <div :key="item.variable">
              {{item.name}}{{item.unit}}
              <a-popover>
                <span slot="content">{{item.tip}}</span>
                <a-icon class="icon-table-tip" type="question-circle" />
              </a-popover>
            </div>
          </template>
          <!-- 不可编辑插槽 -->
          <template v-for="(item, index) in columns.filter(x => !x.needEdit)" :slot="`skuName${index}`" slot-scope="current">
            <span :key="item.dataIndex" style="padding:0 8px">{{current}}</span>
          </template>
          <!-- 自定义输入框 -->
          <template v-for="item in needEdit" :slot="item.variable" slot-scope="current, record">
            <div v-if='item.variable==="skuPic"' :key="item.variable" style='padding:8px;'>
              <picture-card-upload v-if="record[item.variable]||!syncFlagValue||!isCollect||isPlatform" :fileList="record[item.variable]?[record[item.variable]]:[]" :max="1" @update:fileList='res=>record[item.variable]=res.length?res[0]:""' :isPreview="isCollect && syncFlagValue && !isPlatform" />
              <div v-else>无</div>
            </div>
            <div v-else-if="item.variable === 'skuType'" :key="item.variable + 1" >
              {{Number(record[item.variable]) ? Number(record[item.variable]) === 3 ? '卡密':'直充':'请先输入编码'}}
            </div>
            <a-form-model-item v-else-if='item.variable==="string"' :key="item.variable" :class="{'has-error': record[`${item.variable}Error`], disabled: isCollect && !item.collectEdit}" :help="record[`${item.variable}Error`]">
              <a-input v-if='item.variable==="string"' v-model.trim="record[item.variable]" placeholder="请输入" :disabled="isCollect && !item.collectEdit" />
            </a-form-model-item>
            <a-form-model-item v-else :key="item.variable" :class="{'has-error': record[`${item.variable}Error`], disabled: isCollect && !item.collectEdit}" :help="record[`${item.variable}Error`]">
              <a-input v-model.trim="record[item.variable]" placeholder="请输入" @blur="handleTableInputBlur(arguments[0].target.value, record, item.variable)" @input="handleTableInput(arguments[0].target.value, record, item.variable)" :disabled="isCollect && !item.collectEdit" />
            </a-form-model-item>
          </template>
          <!-- table footer批量设置 -->
          <template slot="footer">
            <div class="batch-setting">
              <span class="label">批量设置：</span>
              <div class="setting-list" v-if="!batchSetting.variable">
                <div v-for="item in needEdit.filter(x => isCollect ? x.collectEdit : x)" :key="`${item.variable}Edit`" style='display:inline-block'>
                  <a-button v-if="item.type !=='image' && item.variable!=='skuNo'" type="link" @click="batchSetting.variable = item.variable">
                    {{item.name}}
                  </a-button>
                </div>
              </div>
              <div class="setting-input-box" v-else>
                <a-form-model-item :class="{'has-error': batchSetting.error}" :help="batchSetting.error">
                  <a-input v-model.trim="batchSetting.value" @input="batchSetting.error = tableInputValidate(arguments[0].target.value, batchSetting.variable)" placeholder="请输入" />
                </a-form-model-item>
                <a-button type="link" @click="handleBatchSettingSave">保存</a-button>
                <a-button type="link" @click="Object.assign(batchSetting, { variable: '', error: '' })">取消</a-button>
              </div>
            </div>
          </template>
        </a-table>
      </a-col>
    </a-row>
    <a-row style='margin:12px 0 0;' align='middle' type='flex' v-if="form.skuList.length">
      <a-col :span='3' style='text-align:right;'>精度值：</a-col>
      <a-col :span='21'>
        <a-radio-group v-model="roundingType" :disabled='countryUnifyFlag&&isCollect' @change="changeRoundingType">
          <a-radio :value="0">
            保留整数
          </a-radio>
          <a-radio :value="1">
            保留1位小数
          </a-radio>
          <a-radio :value="2">
            保留2位小数
          </a-radio>
        </a-radio-group>
      </a-col>
    </a-row>
    <a-row :gutter='24' style='margin:12px 0 20px;' v-if="form.skuList.length">
      <a-col :span='21' :offset='3' style="padding:0;color: rgba(0, 0, 0, 0.45);">
        <div>精度值设置只作用于“零售价”，“划线价”，且精度取舍不影响加价率。</div>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
import { goods } from '@/api'
import { ref, computed, watch } from '@vue/composition-api'
import pick from 'lodash/pick'
import NP from 'number-precision'
const skuLimit = 3 // 限制最多可以添加多少个规格

export default {
  name: 'SkuBatchSetting',

  props: {
    syncFlag: Boolean,
    needEdit: Array,
    getDefaultObj: Function,
    typeRateFormat: Function,
    initData: Object,
    isCollect: Boolean,
    getPriceByTax: Function,
    getMathRound: Function,
    countryUnifyFlag: Boolean,
    isPlatform: Boolean,
    productType: String
  },

  setup (props, { root }) {
    const formModelRef = ref(null)
    const form = ref({
      specifications: [],
      skuList: [],
    })
    const syncFlagValue = ref(false)
    const roundingType = ref(2)
    const hasInit = ref(!(root.$route.params.productId || root.$route.params.params))
    watch(
      () => props.initData,
      async (data) => {
        const { skuList, specList, specOptionList } = data
        if (skuList.length) {
          roundingType.value = skuList[0].roundingType
          form.value.specifications = specList.map((x) => ({
            name: x.name,
            children: specOptionList
              .filter((j) => j.code.indexOf(x.code) >= 0)
              .map((j) => ({ name: j.value })),
          }))
          setSkuList()
          await root.$nextTick()
          form.value.skuList.forEach((x) => {
            const matching = skuList.find((j) => j.skuCode === x.skuCode)
            if (matching) {
              Object.assign(
                x,
                props.typeRateFormat(
                  pick(
                    matching,
                    props.needEdit.map((j) => j.variable)
                  ),
                  true
                )
              )
            }
            if (root.$route.params.params) handleTableInput(x.supplyPrice, x, 'supplyPrice')
          })
          hasInit.value = true
          if (props.isCollect) {
            const cantCollectEdit = ['price', 'priceMarkup', 'originalPriceMarkup', 'originalPrice']
            cantCollectEdit.forEach((item) => {
              props.needEdit.forEach((x) => {
                if (item === x.variable) {
                  x.collectEdit = !props.countryUnifyFlag
                }
              })
            })
          }
          handleTableInputBlur()
        }
      }
    )
    watch(
      () => props.syncFlag,
      (val) => {
        syncFlagValue.value = val
      },
      { immediate: true, deep: true }
    )
    const columnsBasic = ref([])
    watch(
      () => props.needEdit,
      (val, _val) => {
        columnsBasic.value = props.needEdit.map((x) => ({
          dataIndex: x.variable,
          needEdit: true,
          scopedSlots: {
            customRender: x.variable,
          },
          ...(x.tip
            ? {
              slots: { title: `${x.variable}Title` },
            }
            : {
              title: `${x.name}${x.unit || ''}`,
            }),
        }))
      },
      { immediate: true, deep: true }
    )
    // 记录规格明细中用户填写数据
    const tableEditBack = ref({})
    const columns = computed(() => {
      return form.value.specifications
        .filter(
          (x) => x.name && x.children.length && x.children.filter((j) => j.name && !j.error).length
        )
        .map((x, i) => ({
          title: x.name,
          dataIndex: `skuName${i}`,
          scopedSlots: {
            customRender: `skuName${i}`,
          },
        }))
        .concat(columnsBasic.value)
    })

    async function setSkuList () {
      await root.$nextTick()
      const skus = combine(
        form.value.specifications
          .filter(
            (x) =>
              x.name &&
              !x.error &&
              x.children.length &&
              x.children.filter((j) => j.name && !j.error).length
          )
          .map((x) => x.children.filter((x) => x.name && !x.error).map((x) => x.name))
      )
      let skuList = []
      if (skus.length) {
        for (let { skuCode, value } of skus) {
          let skuItem = { skuCode }
          for (let i = 0; i < value.length; i++) {
            skuItem[`skuName${i}`] = value[i]
          }
          skuList.push({
            ...skuItem,
            ...props.getDefaultObj(true),
          })
        }
      }
      skuList.forEach((x) => {
        if (tableEditBack.value[x.skuCode]) {
          Object.assign(
            x,
            pick(tableEditBack.value[x.skuCode], [
              ...props.needEdit.map((x) => x.variable),
              ...props.needEdit.map((x) => `${x.variable}Error`),
            ])
          )
        }
      })
      form.value.skuList = skuList
    }

    function handleAddSpecification () {
      form.value.specifications.push({
        name: '',
        children: [],
      })
    }

    function handleAddSpecificationDetail (i) {
      form.value.specifications[i].children.push({ name: '' })
    }

    function handleSkuValidate (e, current, type, skuDetailList) {
      let val = e.target.value
      current.error = ''
      if (!val) current.error = '请输入'
      if (val.length > 32) current.error = '请尝试输入简短一些'
      if (type === 'sku') {
        form.value.specifications.forEach((x) => {
          x.error =
            form.value.specifications.filter((j) => j.name === x.name).length > 1 ? '输入重复' : ''
        })
      } else {
        skuDetailList.forEach((x) => {
          x.error = skuDetailList.filter((j) => j.name === x.name).length > 1 ? '输入重复' : ''
        })
      }
      setSkuList()
    }

    function handleSkuDelete (i) {
      form.value.specifications.splice(i, 1)
      setSkuList()
    }

    function handleSkuDetailDelete (i, j) {
      form.value.specifications[i].children.splice(j, 1)
      setSkuList()
    }

    function handleTableInputBlur (value, record, variable) {
      if (variable === 'skuNo' && props.productType === '6') {
        getSkuType(value, record)
      }
      form.value.skuList.forEach((x) => {
        tableEditBack.value[x.skuCode] = pick(
          x,
          props.needEdit
            .map((x) => x.variable)
            .concat(props.needEdit.map((x) => `${x.variable}Error`))
        )
      })
    }
    async function getSkuType (value, record) {
      if (value) {
        const { code, msg, data } = await goods.getSkuInfoForFuLu(value)
        if (code === '00000') {
          record.tradePrice = data.supplyPrice
          record.skuType = data.skuType
          handleTableInput(data.supplyPrice, record, 'tradePrice')
        } else {
          this.$message.error(msg || '编码查询数据有误')
        }
      }
    }
    function changeRoundingType (e) {
      const precision = Number(e.target.value)
      form.value.skuList.forEach((item) => {
        if (item.price) {
          item.price = props.getPriceByTax(
            item.tradePrice,
            NP.divide(item.priceMarkup, 100),
            precision
          )
        }
        if (item.originalPrice) {
          item.originalPrice = props.getPriceByTax(
            item.tradePrice,
            NP.divide(item.originalPriceMarkup, 100),
            precision
          )
        }
        item.roundingType = precision
      })
    }
    // sku表单输入验证及含税价根据税率计算
    function handleTableInput (value, record, variable) {
      console.log(111111)
      const error = tableInputValidate(value, variable)
      record[`${variable}Error`] = error
      // 成本价计算供货价
      if (variable === 'supplyPrice' && !record.tradePriceMarkupError && +value !== 0) {
        record.tradePrice = props.getPriceByTax(value, NP.divide(record.tradePriceMarkup, 100))
        calcPriceByTradePrice(record)
      }
      // 供货价加价率计算供货价
      if (
        variable === 'tradePriceMarkup' &&
        !error &&
        !record.supplyPriceError &&
        +record.supplyPrice !== 0
      ) {
        record.tradePrice = props.getPriceByTax(record.supplyPrice, NP.divide(value, 100))
        calcPriceByTradePrice(record)
      }
      // 划线价加价率/零售价加价率计算划线价/零售价
      const arrRates = ['priceMarkup', 'originalPriceMarkup']
      if (arrRates.some((x) => x === variable) && !error && !record.tradePriceError) {
        const priceVariable = variable.split('Markup')[0]
        record[priceVariable] = props.getPriceByTax(
          record.tradePrice,
          NP.divide(value, 100),
          roundingType.value
        )
      }
      // 供货价计算供货价加价率
      if (variable === 'tradePrice') {
        calcPriceByTradePrice(record)
        if (!error && !record.supplyPriceError && +record.supplyPrice !== 0) {
          const markup = NP.round(
            NP.times(NP.minus(NP.divide(value, record.supplyPrice), 1), 100),
            2
          )
          record.tradePriceMarkup = markup < 0 ? 0 : markup
        }
      }
      // 划线价/零售价计算划线价加价率/零售价加价率
      const arrPrices = ['price', 'originalPrice']
      if (arrPrices.some((x) => x === variable) && !error && !record.tradePriceError) {
        const markup = NP.round(NP.times(NP.minus(NP.divide(value, record.tradePrice), 1), 100), 2)
        record[`${variable}Markup`] = markup < 0 ? 0 : markup
      }
    }

    // 根据供货价计算含税供货价  、划线价/零售价
    function calcPriceByTradePrice (record) {
      if (props.countryUnifyFlag && props.isCollect) {
        const arrPrices = ['price', 'originalPrice']
        for (let key in record) {
          if (arrPrices.indexOf(key) !== -1) {
            record[key + 'Markup'] = NP.round(
              NP.times(NP.minus(NP.divide(record[key], record.tradePrice), 1), 100),
              2
            )
          }
        }
      } else {
        const tradePriceError = tableInputValidate(record.tradePrice, 'tradePrice')
        if (tradePriceError) return
        ;['originalPriceMarkup', 'priceMarkup'].forEach((x) => {
          if (record[`${x}Error`]) return
          const variable = x.split('Markup')[0]
          record[variable] = props.getPriceByTax(
            record.tradePrice,
            NP.divide(record[x], 100),
            roundingType.value
          )
        })
      }
    }

    // 批量设置sku
    const batchSetting = ref({
      value: '',
      variable: '',
      error: '',
    })
    function handleBatchSettingSave () {
      batchSetting.value.error = tableInputValidate(
        batchSetting.value.value,
        batchSetting.value.variable
      )
      const { error, variable } = batchSetting.value
      if (!error) {
        form.value.skuList.forEach((x) => {
          x[variable] = batchSetting.value.value
          handleTableInput(batchSetting.value.value, x, variable)
        })
        batchSetting.value.value = ''
        batchSetting.value.variable = ''
        handleTableInputBlur()
      }
    }

    function tableInputValidate (value, variable) {
      let regExp = ''
      const validateType = props.needEdit.find((x) => x.variable === variable)
      if (variable === 'skuPic') return ''
      if (variable === 'skuNo') return ''
      if (variable === 'points') return ''
      if (variable === 'weight' || validateType.type === 'rate') {
        regExp = /^(([0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/
      } else if (variable === 'stock') {
        regExp = /^\+?[0-9][0-9]*$/
      } else {
        regExp =
          validateType.type === 'integer'
            ? /^\+?[1-9][0-9]*$/
            : /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/
      }
      if (value === '' && !props.isCollect) return '请输入'
      if (!regExp.test(value)) return '格式有误'
      // if (validateType.type === 'rate' && value > 100) return '超出范围'
      return ''
    }

    function generateBig (length = 26) {
      let chBig = 'A'
      let strBig = ''
      for (var i = 0; i < length; i++) {
        strBig += String.fromCharCode(chBig.charCodeAt(0) + i)
      }
      return strBig
    }
    // 全排列算法生成sku列表
    const skuCodes = generateBig(skuLimit)
    function combine (chunks) {
      if (!chunks.length) return []
      let res = []

      let helper = function (chunkIndex, prev) {
        let chunk = chunks[chunkIndex]
        let isLast = chunkIndex === chunks.length - 1
        let i = 0
        for (let val of chunk) {
          let cur = {
            skuCode: `${prev.skuCode || ''}${skuCodes[chunkIndex]}${padding(++i)}`,
            value: prev.value.concat(val),
          }
          if (isLast) {
            // 如果已经处理到数组的最后一项了 则把拼接的结果放入返回值中
            res.push(cur)
          } else {
            helper(chunkIndex + 1, cur)
          }
        }
      }

      // 从属性数组下标为 0 开始处理
      // 并且此时的 prev 是个空数组
      helper(0, {
        skuCode: '',
        value: [],
      })

      return res
    }

    // 数字前置补0
    function padding (num, length = 3) {
      return (Array(length).join('0') + num).slice(-length)
    }

    function handleSubmit () {
      return new Promise((resolve, reject) => {
        const { specifications, skuList } = form.value
        skuList.forEach((x) => {
          props.needEdit.forEach(({ variable }) => {
            x[`${variable}Error`] = tableInputValidate(x[variable], variable)
          })
        })
        const specificationsError = specifications.some(
          (x) => x.error || x.children.some((j) => j.error)
        )
        const skuError = skuList.some((x) =>
          Object.keys(x).some((j) => j.indexOf('Error') >= 0 && x[j])
        )
        if (specificationsError || skuError) return reject(new Error('表单校验失败'))
        const skuPriceValid = skuPriceValidate()
        if (skuPriceValid) {
          root.$message.warning(skuPriceValid)
          return reject(new Error('表单校验失败'))
        }
        const skuParams = {
          specList: specifications.map((x, i) => ({
            code: skuCodes[i],
            name: x.name,
            seqNum: i + 1,
          })),
          specOptionList: getSpecOptionList(specifications),
          skuList: skuList.map((x, i) => {
            const initSku =
              props.initData && props.initData.skuList && props.initData.skuList[i]
                ? props.initData.skuList[i]
                : {}
            const collectProductSkuId = initSku.collectProductSkuId || ''
            const supplyProductSkuId = initSku.supplyProductSkuId || ''
            return {
              ...props.typeRateFormat(x),
              skuName: Object.keys(x)
                .filter((j) => j.indexOf('skuName') >= 0)
                .map((j) => x[j])
                .join(''),
              seqNum: i + 1,
              collectProductSkuId:
                collectProductSkuId === initSku.productSkuId ? '' : collectProductSkuId,
              supplyProductSkuId:
                supplyProductSkuId === initSku.productSkuId ? '' : supplyProductSkuId,
              productSkuGeneralId: initSku.productSkuGeneralId || '',
            }
          }),
        }
        resolve({
          weight: '',
          changeFlag: getChangeFlag(skuParams),
          anyPriceChangeFlag: getAnyPriceChangeFlag(skuParams),
          ...skuParams,
        })
      })
    }
    // 判断 sku 任意价格变动 或 sku 增删
    function getAnyPriceChangeFlag (params) {
      if (!Object.keys(props.initData).length) return false
      const { skuList } = props.initData
      if (skuList.length !== params.skuList.length) return true
      for (let i = 0; i < skuList.length; i++) {
        const arr = ['price', 'originalPrice', 'supplyPrice', 'tradePrice']
        for (let j = 0; j < arr.length; j++) {
          if (
            skuList[i][arr[j]] !== params.skuList[i][arr[j]] &&
            skuList[i][arr[j]] !== undefined &&
            params.skuList[i][arr[j]] !== undefined
          ) {
            return true
          }
        }
      }
      return false
    }
    // 判断sku是否发生更改
    function getChangeFlag (params) {
      if (!Object.keys(props.initData).length) return false
      const { specList, specOptionList, skuList } = props.initData
      if (skuList.length !== params.skuList.length) return true
      for (let i = 0; i < specList.length; i++) {
        if (specList[i].name !== params.specList[i].name) return true
      }
      for (let i = 0; i < specOptionList.length; i++) {
        if (specOptionList[i].value !== params.specOptionList[i].value) return true
      }
      for (let i = 0; i < skuList.length; i++) {
        for (let { variable } of props.needEdit) {
          if (skuList[i][variable] !== params.skuList[i][variable]) return true
        }
      }
      return false
    }

    // sku提交价格验证
    function skuPriceValidate () {
      for (let i = 0; i < form.value.skuList.length; i++) {
        const record = form.value.skuList[i]
        const warningTitle = `商品明细第${i + 1}条，`
        // 供货价不小于成本价
        if (+record.tradePrice < +record.supplyPrice) return `${warningTitle}供货价需不小于成本价`
        if (+record.price < +record.tradePrice) return `${warningTitle}零售价价需不小于供货价`
        // 划线价不小于含税供货价
        if (+record.originalPrice < +record.price) {
          return `${warningTitle}划线价需不小于零售价`
        }
      }
      return ''
    }

    // 获取提交参数 specOptionList
    function getSpecOptionList (list) {
      let optionList = []
      let seqNum = 0
      list.forEach((x, i) => {
        x.children.forEach((j, k) => {
          if (j.name) {
            optionList.push({
              value: j.name,
              code: `${skuCodes[i]}${padding(k + 1)}`,
              seqNum: ++seqNum,
            })
          }
        })
      })
      return optionList
    }

    return {
      skuLimit,
      formModelRef,
      syncFlagValue,
      form,
      columns,
      setSkuList,
      handleAddSpecification,
      handleAddSpecificationDetail,
      handleSkuValidate,
      handleSkuDelete,
      handleSkuDetailDelete,
      handleTableInputBlur,
      getSkuType,
      handleTableInput,
      batchSetting,
      handleBatchSettingSave,
      tableInputValidate,
      handleSubmit,
      changeRoundingType,
      roundingType,
    }
  },
}
</script>
<style lang="less" scoped>
.sku-setting {
  .custom-table {
    ::v-deep .ant-table-footer {
      padding: 6px 16px;
      .ant-form-item-with-help {
        margin: 0;
      }
    }
    ::v-deep .ant-form-item {
      padding: 0 8px;
      margin-right: 0;
      &.disabled {
        background-color: #f5f5f5;
      }
    }
  }
  ::v-deep .ant-table-body {
    .ant-form-item-control-wrapper {
      width: auto;
    }
    .ant-form-item.ant-form-item-with-help {
      margin-bottom: 8px;
      margin-right: 0;
    }
    .ant-form-inline .ant-form-item {
      margin-right: 0;
    }
    .ant-table-thead > tr > th {
      padding: 8px;
    }
    .ant-table-tbody > tr > td {
      padding: 0;
    }
    .ant-input {
      padding: 4px 0;
      border: none;
      outline: none;
      background-color: transparent;
      &:focus {
        border: none;
        box-shadow: none;
      }
    }
    .ant-form-explain {
      text-align: left;
    }
  }
  ::v-deep .ant-form-item-required::before {
    display: none;
  }
  ::v-deep .ant-form-item-control-wrapper {
    width: 210px;
  }
}
.ant-form-item-required {
  line-height: 40px;
}
.specifications-box {
  padding: 14px;
  border: 1px solid #dcdfe5;
}
.btn-del {
  display: none;
  position: absolute;
  z-index: 2;
  top: 50%;
  right: 15px;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-size: 16px;
  background: hsla(0, 0%, 60%, 0.6);
  border-radius: 12px;
  transform: translate3d(0, -50%, 0);
  .icon-close {
    color: #fff;
    font-size: 12px;
  }
  &:hover {
    background: #333;
  }
}
.line {
  position: relative;
  padding: 8px 12px;
  background-color: #f3f6f8;
  &.no-padding {
    padding: 8px 0;
  }
  &:hover .btn-del {
    display: flex;
  }
}
.detail-list ::v-deep .ant-form-item {
  &:hover .btn-del {
    display: flex;
  }
  .btn-del {
    top: -5px;
    right: -7px;
    width: 18px;
    height: 18px;
    .icon-close {
      font-size: 8px;
    }
  }
}
.specification-detail {
  display: flex;
  padding: 8px 12px;
  .label {
    flex: none;
  }
}
.custom-error {
  color: #f5222d;
}
.icon-table-tip {
  color: #cacaca;
}
.batch-setting {
  display: flex;
  .setting-list {
    padding: 4px 0;
  }
  .label {
    flex: none;
  }
}
.setting-input-box {
  display: flex;
  ::v-deep .ant-btn {
    margin-top: 4px;
  }
}
</style>
